.chapter_from {
  form {
    .row {
      margin: 0;
    }

    .inputField {
      display: flex;
      flex-direction: column;
      width: 95%;
      margin: 0 0 2rem;

      @include inputFields;
    }

    .upload {
      display: flex;
      flex-direction: column;
      width: 95%;
      margin: 0 0 2rem;

      label {
        font-size: 1rem;
        font-family: secondaryFont;
        color: $primaryColor;
      }

      &_icon {
        width: fit-content;
        padding: 1rem 0;
        border: 2px dashed $primaryColor;
        border-radius: 5px;
        background-color: $fifthColor;
        outline: none;
        display: flex;
        align-items: center;
        font-family: secondaryFont;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          label {
            color: $tertiaryColor;
            padding: 0 0 0.5rem;
          }

          input {
            color: $fourthColor;
          }
        }

        img {
          border: 1px solid $primaryColor;
          width: 70px;
          height: 70px;
          border-radius: 5px;
          padding: 0.05rem;
          margin: 0 1rem;
        }

        button {
          margin-right: 1rem;
          border: 2px solid $primaryColor;
          background-color: transparent;
          border-radius: 3px;
          font-size: 1rem;
          padding: 0.3rem 0.8rem;
          color: $primaryColor;
          transition: 200ms ease-in-out;

          &:hover {
            background-color: $primaryColor;
            color: white;
          }
        }
      }
    }

    @media only screen and (max-width: 700px) {
      .upload {
        margin: 0 0 1rem;

        &_icon {
          width: 100%;
          align-items: flex-start;
          flex-direction: column;
          padding-left: 1rem;

          & > div {
            margin: 1rem 0;
          }
        }
      }
    }

    .submitBtn {
      border: none;
      background-color: $primaryColor;
      width: 95%;
      font-size: 1.2rem;
      border-radius: 5px;
      color: white;
      font-family: primaryFont;
      padding: 0.5rem 0;
      cursor: pointer !important;

      i {
        font-size: 1rem;
      }
    }
  }
}
