.slider {
  position: relative;

  .chapter_slider-block {
    margin: 1rem 0;
    width: 90%;
    border-radius: 10px;
    text-align: center;
    font-family: tertiaryFont;
    transition: 100ms ease-in-out;
    border: 3px solid white;
    cursor: pointer;

    & > div {
      padding: 1rem;
    }

    .first_part {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: $fifthColor;
      border-bottom: 1px solid rgb(201, 199, 199);
      height: 160px;
    }

    .second_part {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: $sixthColor;
      height: 130px;
      text-align: start;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .subject_name {
        display: flex;
        justify-content: space-between;

        font-size: 1rem;
        color: $tertiaryColor;

        i {
          color: $primaryColor;
          transition: 200ms ease-in-out;
          cursor: pointer;

          &:hover {
            transform: scale(1.3);
          }
        }
      }

      .chapter_name {
        font-size: 0.9rem;
        color: $black;
        font-weight: 500;
        line-height: 1.2rem;
        white-space: nowrap;

        span {
          font-family: primaryFont;
        }
      }

      .total {
        font-size: 1rem;
        color: $primaryColor;

        span + span {
          margin-left: 0.5rem;
          color: $black;
        }
      }
    }

    &:hover {
      border: 3px solid $primaryColor;
      box-shadow: 2px 2px 10px 0 rgb(124, 123, 123);
    }
  }

  .selected {
    box-shadow: 2px 2px 10px 0 rgb(124, 123, 123);
    border: 3px solid $primaryColor;
  }
}
