.allChapters {
  &_wrapper {
    margin: 1rem 0 0;
    border-radius: 5px;
    background-color: $fifthColor;
    padding: 1rem;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 0.5rem;

      h1 {
        flex-grow: 1;
        font-size: 1.2rem;
      }

      .manageSequenceBtn {
        @include generalBtn_1;
        width: unset;
        padding: 0.5rem 1rem;
        font-family: primaryFont;
        margin-right: 0.5rem;
      }
    }

    .note {
      padding: 0.5rem 0;
      color: $tertiaryColor;
      font-family: secondaryFont;

      span {
        color: $fourthColor;

        i {
          position: relative;

          &:first-child {
            padding-left: 0.5rem;
            padding-right: 0.2rem;
          }

          &:last-child {
            padding-right: 0.5rem;
          }
        }
      }

      .grey {
        height: 20px;
        background-color: #d4d4d4;
        padding: 0 1rem;
        margin: 0 0.2rem;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      p {
        padding: 1rem;
        padding-left: 0;
        color: $tertiaryColor;
        font-family: secondaryFont;
      }

      .detail {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $secondaryColor;
        padding: 1rem;
        border-radius: 5px;

        .dragPoint {
          i {
            padding: 0 0.1rem;
            color: $fourthColor;
            cursor: unset;
          }
          padding-right: 1rem;

          &:hover {
            i {
              color: $tertiaryColor;
            }
          }
        }

        h1 {
          font-size: 1rem;
          font-family: primaryFont;
        }

        .left {
          flex-grow: 1;
          h1 {
            width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .right {
          display: flex;
          align-items: center;
          .action {
            cursor: pointer;
            i {
              margin-left: 1rem;
              color: $primaryColor;
              transition: 250ms ease-in-out;

              &:hover {
                color: $tertiaryColor;
                transform: scale(1.2);
              }
            }

            .status {
              background-color: green;
              border-radius: 50%;
              color: white;
              padding: 0.25rem;
              font-size: 0.8rem;

              &:hover {
                color: white;
              }
            }

            .ban {
              color: $tertiaryColor;
            }
          }
        }
      }

      .inactive {
        background-color: #d4d4d4;
      }
    }

    .mobileDnd_content {
      // border: 1px solid red;
      margin: 1rem 0;
      background-color: $secondaryColor;
      padding: 1rem;
      border-radius: 5px;

      .index {
        border-radius: 50%;
        background-color: $seventhColor;
        color: white;
        padding: 0.2rem 0.5rem;
        border-radius: 5px;
        text-align: center;
      }

      label {
        font-size: 1rem;
        font-family: secondaryFont;
        color: $primaryColor;
      }

      h1 {
        margin: 0.5rem 0;
        font-size: 1rem;
        font-family: primaryFont;
      }

      .action {
        i {
          padding-right: 1rem;
          color: $primaryColor;
          cursor: pointer;
          transition: 250ms ease-in-out;

          &:hover {
            color: $tertiaryColor;
          }
        }
      }
    }
  }
}
