.createLesson_btn {
  button {
    padding: 0.7rem 1rem;
    color: white;
    background-color: $primaryColor;
    font-family: primaryFont;
    border: none;
    border-radius: 5px;
    font-size: 0.8rem;

    i {
      padding: 0 0.5rem;
    }
  }
}
