.dinar_wrapper {
  &-add {
    text-align: end;
  }

  button {
    @include generalBtn;
    padding: 0.6rem 1.5rem;
  }
}
