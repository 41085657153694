.change_status {
  h1 {
    text-align: center;
  }

  &-actions {
    margin: 1rem 0 0;
    display: flex;
    justify-content: space-between;

    button {
      border: 2px solid $primaryColor;
      color: $primaryColor;
      width: 200px;
      padding: 0.5rem 0;
      font-family: secondaryFont;
      border-radius: 5px;
      transition: 200ms ease-in-out;

      &:hover {
        font-weight: bold;
        background-color: $primaryColor;
        color: $secondaryColor;
      }
    }
  }
}
