.chapter_view {
  &-details {
    .header {
      display: flex;
      align-items: flex-end;

      img {
        border-radius: 5px;
        width: 50px;
        height: 50px;
        box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
      }

      h1 {
        margin: 0 1rem;

        span + span {
          color: $tertiaryColor;
        }
      }
    }

    .action {
      margin: 2rem 0 0;
      display: flex;
      justify-content: space-between;

      .info_block {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;

        span {
          border-radius: 5px;
          padding: 0.3rem 1rem;
          background-color: $fifthColor;
          margin-right: 1rem;
          color: $primaryColor;
          font-family: primaryFont;
          margin: 0 0.5rem 0.5rem 0;
        }
      }

      .edit {
        @include normalBtn;
        width: fit-content;
        white-space: nowrap;
        margin: 0 0 0.5rem;
        text-decoration: none;
      }
    }

    .table_container {
      .table-content {
        table {
          thead {
            .header {
              tr {
                th {
                  &:last-child {
                    width: 100vw;

                    .table-column-title {
                      margin: 0 auto;
                      width: fit-content;
                    }
                  }
                }
              }
            }
          }

          tbody {
            .rowBg {
              tr {
                td {
                  .chapterView_status {
                    p {
                      text-align: center;
                      border-radius: 20px;
                      font-size: 0.7rem;
                      padding: 0.15rem 0;
                      width: 100px;
                      color: white;
                      font-family: primaryFont;
                    }

                    .green {
                      border: 1px solid #92c04c;
                      background-color: #92c04c;
                    }

                    .red {
                      border: 1px solid #eb5757;
                      background-color: #eb5757;
                    }
                  }

                  &:last-child {
                    width: 100%;

                    .chapterView_actions {
                      margin: 0 auto;
                      width: fit-content;

                      i {
                        cursor: pointer;
                        color: $primaryColor;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // chapterView_actions
  }
}
