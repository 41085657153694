.stepper {
  //   width: 100%;
  position: relative;
  right: 2rem;

  &_container {
    li {
      list-style-type: none;
      float: left;
      width: 15%;
      position: relative;
      font-weight: 600;
      font-size: 0.9rem;
      color: $fourthColor;
      font-family: primaryFont;
      // cursor: pointer;
    }

    counter-reset: step;
    li::before {
      /* CSS for creating step er block before the li item*/
      content: '';
      content: counter(step);
      counter-increment: step;
      position: absolute;
      border: 2px solid rgb(239, 237, 237);
      border-radius: 50%;
      height: 40px;
      width: 40px;
      top: -3rem;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e1e1eb;
      color: #828282;
      transition: 300ms ease-in-out;
      transform: translateX(-50);
      transform-origin: left;
    }

    li::after {
      //     /* CSS for creating horizontal line*/
      content: '';
      position: absolute;
      height: 50px;
      width: 95%;
      height: 2px;
      background-color: #cbcbd1;
      top: -1.8rem;
      left: -90%;
      z-index: -1;
      transition: 300ms ease-in-out;
      transform: translateX(-50);
      transform-origin: left;
    }

    li:first-child:after {
      content: none;
    }

    li.active::before {
      background-color: $tertiaryColor;
      border-color: $tertiaryColor;
      color: white;
      transform: translateX(0);
    }

    li.complete::before {
      background-color: rgb(26, 140, 26);
      border-color: rgb(26, 140, 26);
      transform: translateX(0);
      color: white;
    }

    li.active::after {
      background-color: $tertiaryColor;
      transform: translateX(0);
      // transform: scaleX(1);
    }

    li.active {
      color: $tertiaryColor;
    }

    li.complete {
      color: rgb(26, 140, 26);
    }
  }
}
