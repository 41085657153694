.generalSettings {
  .advertisement {
    h1 {
      i {
        margin-right: 0.5rem;
        font-size: 1.1rem;
        padding: 0.2rem;
        border-radius: 50%;
      }

      .red {
        color: $tertiaryColor;
      }

      .green {
        background-color: green;
        color: white;
      }
    }

    &-image {
      max-width: 500px;

      img {
        width: 100%;
      }
    }

    &-actions {
      button {
        border: 2px solid $primaryColor;
        font-family: secondaryFont;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        background-color: transparent;
        margin-right: 1rem;
      }

      .cancel {
        color: $primaryColor;
      }
      .upload,
      .status {
        color: white;
        background-color: $primaryColor;
      }
    }
  }

  &-upload {
    visibility: hidden;
  }
}
