.upload_pdf {
  margin: 1rem 0;

  #form-file-upload {
    height: 12rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: $primaryColor;
    background-color: transparent;
    font-family: secondaryFont;

    & > div {
      color: $tertiaryColor;
      color: $tertiaryColor;
      display: flex;
      align-items: center;

      i {
        padding: 0 0.2rem;
      }

      p {
        margin: 0;
      }

      button {
        font-family: secondaryFont;
        color: $tertiaryColor;
      }
    }
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  span {
    cursor: pointer;
    font-size: 1rem;
    color: $primaryColor;
    font-family: secondaryFont;
    transition: 200ms ease-in-out;
    position: relative;
    top: 0.5rem;

    &:hover {
      color: $tertiaryColor;
    }
  }

  & > .actions {
    display: flex;
    flex-direction: column;

    .removeBtn {
      @include hoverBtn;
      box-shadow: none !important;
      margin: 1rem 0 0;
      width: fit-content;
    }

    .videoIframesContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .iFrameContainer {
      display: flex;
      flex-direction: column;
    }

    .rightMargin {
      margin-right: 20px;
    }

    .pdf_viewer {
      border: 1px solid red;
      max-height: 600px;
      max-width: 600px;
      width: 100%;
      height: 100%;
      overflow: auto;
      cursor: pointer;
    }

    .pdf_doc,
    .pdf_page {
      width: 100%;
      max-width: 100%;
    }
  }

  .videoProcessing {
    margin-bottom: 15px;
  }

  .uploadBarContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .uploadBarStyle {
    width: 200px;
    height: 30px;
  }

  .uploadBarTextStyle {
    color: $primaryColor;
    pointer-events: none;
  }

  .iframeReloadButton {
    cursor: pointer;
  }
}
