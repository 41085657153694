.reports {
  &-wrapper {
    &_dateRange {
      display: flex;

      .input-field {
        width: 50%;
      }

      .input-field:last-child {
        margin-left: 1rem;
      }
    }

    .actions {
      text-align: end;

      button {
        @include generalBtn;
      }

      .disable {
        background-color: rgb(218, 216, 216);
        color: rgb(138, 137, 137);
      }
    }
  }

  .download {
    visibility: hidden;
  }
}
