$primaryColor: #37358d;
$secondaryColor: #f2f2f2;
$tertiaryColor: #ec1d69;
$fourthColor: #828282;
$fifthColor: #e1e1eb;
$sixthColor: #ededf3;
$black: #333333;
$seventhColor: #bdbdbd;
$eightColor: #4f4f4f;
$ninthColor: #cccccc;
$green: #219653;

@import 'scss';

// $secondaryColor: #ededf3;
