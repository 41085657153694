.question-form {
  padding: 1rem;
  background-color: $fifthColor;
  border-radius: 5px;

  .title {
    font-size: 1rem;
  }

  label {
    font-size: 1rem;
    font-family: secondaryFont;
    color: $primaryColor;
  }

  .inputField {
    display: flex;
    flex-direction: column;

    label {
      font-size: 1rem;
      font-family: secondaryFont;
      color: $primaryColor;
      padding: 0 0 0.3rem;
    }

    .addBlankBtn {
      @include generalBtn;
      padding: 1;
      color: $fifthColor !important;
      width: 160px;
      min-height: 60px;
      text-align: center;
      background-color: $primaryColor !important;
    }

    input,
    select,
    textarea {
      border: 1px solid $primaryColor;
      padding: 1rem 0.5rem;
      border-radius: 5px;
      font-family: secondaryFont;
      background-color: $fifthColor;
      outline: none;
    }

    select {
      color: $primaryColor;
    }
  }

  .title_level {
    display: flex;

    & div:first-child {
      width: 60%;
      margin-right: 1rem;
    }

    & div:nth-child(2) {
      width: 15%;
      margin-right: 1rem;
    }

    & div:nth-child(3) {
      width: 25%;
    }

    .flex_space_between {
      width: 100% !important;
      display: flex;
      justify-content: space-between;
    }
  }

  .selection {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .inputField {
      flex-grow: 1;
      margin-right: 0.5rem;
    }
  }

  .type_points {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .type {
      display: flex;
      align-items: center;
      width: 420px;

      .level {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $primaryColor;
        padding: 1rem 0.5rem;
        border-radius: 5px;
        font-family: secondaryFont;
        background-color: $secondaryColor;
        outline: none;
        margin-right: 20px;
        color: $primaryColor;

        input {
          width: 25px;
          height: 25px;
          accent-color: $primaryColor;
        }

        label {
          padding-left: 0.5rem;
          text-transform: capitalize;
          width: 100%;
          white-space: nowrap;
        }
      }
    }

    .points {
      font-size: 1rem;
      font-family: secondaryFont;

      i {
        color: $tertiaryColor;
        border-radius: 50%;
        border: 2px solid $tertiaryColor;
        padding: 0.3rem;
        cursor: pointer;
        transition: 200ms ease-in-out;

        &:hover {
          box-shadow: 0 0 3px 5px rgb(166, 163, 163);
        }
      }
      span {
        color: $primaryColor;
        @include notCopied;
      }
    }
  }

  .time {
    label {
      font-family: secondaryFont;
      color: $primaryColor;
    }

    & > div + div {
      font-size: 1rem;
      font-family: secondaryFont;
      border: 2px solid $primaryColor;
      width: fit-content;
      padding: 1.5rem;
      border-radius: 5px;
      @include notCopied;

      span:first-child,
      span:last-child {
        color: $tertiaryColor;
        border-radius: 50%;
        border: 2px solid $tertiaryColor;
        padding: 0.6rem 0.3rem;
        cursor: pointer;
        font-size: 0.9rem;
        transition: 200ms ease-in-out;

        &:hover {
          -webkit-box-shadow: 0 10px 6px -6px #777;
          -moz-box-shadow: 0 10px 6px -6px #777;
          box-shadow: 0 5px 6px 2px #777;
        }
      }

      span:not(:first-child):not(:last-child) {
        font-size: 1.1rem;
        margin: 0 1rem;
        color: $primaryColor;
        width: 300px;
      }
    }
  }

  .note {
    font-size: 1rem;
    color: $tertiaryColor;
    font-family: secondaryFont;
    text-align: justify;
  }

  .options {
    display: flex;
    flex-direction: column;
    font-family: secondaryFont;
    flex-wrap: wrap;

    .option {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      input[type='radio'] {
        width: 30px;
        height: 30px;
        margin: 0 1rem;
      }

      .upload_image {
        margin: 0 1rem;
      }

      .option-textarea-container {
        flex-grow: 1;
      }

      .inputField {
        width: 100%;
        border: 1px solid $primaryColor;
        padding: 1rem 0.5rem;
        border-radius: 5px;
        font-family: secondaryFont;
        background-color: $fifthColor;
        outline: none;
      }

      i {
        margin: 0 1rem;
        cursor: pointer;
      }
    }

    .addOptionBtn {
      @include hoverBtn;
      font-family: secondaryFont;
      padding: 0.5rem 1rem;
      width: fit-content;

      i {
        padding: 0.5rem;
      }
    }
  }

  .row-options {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .option {
      flex-direction: row;
      justify-content: space-around;
      margin-right: 1rem;

      input[type='radio'] {
        margin: 0 1rem 0 0;
      }

      .upload_image {
        margin: 0 1rem;
      }

      .inputField {
        margin: 0 1rem;
      }
    }
  }

  .assign {
    display: flex;
    align-items: center;

    input {
      width: 20px;
      height: 20px;
    }

    p {
      margin: 0 0.5rem;
      color: $tertiaryColor;
      font-size: 1rem;
      font-family: secondaryFont;
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .submitBtn {
      @include generalBtn;
      padding: 1rem 2rem;
      color: $fifthColor;
    }

    .disableBtn {
      @include generalBtn;
      padding: 1rem 2rem;
      color: $fifthColor;
      background-color: $seventhColor;
      cursor: not-allowed;
    }

    div {
      button {
        margin: 0 1rem;

        //  {
        @include hoverBtn;
        padding: 0;
        padding: 0.5rem 1rem;
        position: relative;
        font-family: secondaryFont;
        // }
      }
    }
  }
}
