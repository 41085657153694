.upload_image {
  .upload {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 95%;

    label {
      font-size: 1rem;
      font-family: secondaryFont;
      color: $primaryColor;
    }

    &_icon {
      width: fit-content;
      padding: 1rem 0;
      border: 2px dashed $primaryColor;
      border-radius: 5px;
      background-color: $fifthColor;
      outline: none;
      display: flex;
      align-items: center;
      font-family: secondaryFont;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
          color: $tertiaryColor !important;
          padding: 0 0 0.5rem;
        }

        input {
          color: $fourthColor;
        }
      }

      img {
        border: 1px solid $primaryColor;
        width: 70px;
        height: 70px;
        border-radius: 5px;
        padding: 0.05rem;
        margin: 0 1rem;
      }

      button {
        @include hoverBtn;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .upload {
      margin: 0 0 1rem;

      &_icon {
        width: 100%;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 1rem;

        & > div {
          margin: 1rem 0;
        }
      }
    }
  }
}
