@import './fonts/fonts.scss';

h1 {
  font-size: 1.5rem;
  font-family: secondaryFont;
  color: #37358d;
  font-weight: 600;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

.bgColor {
  background-color: #37358d;
}

.error_span {
  // color: rgb(252, 74, 74);
  color: #ec1d69;
  font-size: 0.75rem;
  font-family: secondaryFont;

  i {
    margin-right: 0.3rem;
  }
}

.required {
  color: $tertiaryColor;
  position: relative;
  top: -0.3rem;
  font-size: 0.6rem;
  margin: 0 0.1rem;
}

@mixin primaryBtn {
  margin: 1.5rem 0 0;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: transparent;
  border: 2px solid #37358d;
  color: #37358d;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin primaryBtn {
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: transparent;
  border: 2px solid #37358d;
  color: #37358d;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
    bottom: 0;
    z-index: -1;
    background-color: #37358d;
    transform: scaleX(0);
    transition: transform 0.4s ease-in-out;
    font-family: secondaryFont;
  }

  &:hover::before,
  &:focus::before {
    transform: scaleX(1);
  }

  &:hover,
  &:focus {
    color: white;
  }
}

@mixin secondaryBtn {
  width: 100%;
  background-color: transparent;
  border: 2px solid #37358d;
  color: #37358d;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  height: 35px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
    bottom: 0;
    z-index: -1;
    background-color: #37358d;
    transform: scaleX(0);
    transition: transform 0.4s ease-in-out;
    font-family: secondaryFont;
  }

  &:hover::before,
  &:focus::before {
    transform: scaleX(1);
  }

  &:hover,
  &:focus {
    color: white;
  }
}

@mixin inputFields {
  label {
    font-size: 1rem;
    font-family: secondaryFont;
    color: $primaryColor;
  }

  input,
  select,
  textarea {
    border: none;
    padding: 1rem 0.5rem;
    border-radius: 5px;
    font-family: secondaryFont;
    background-color: $fifthColor;
    outline: none;
  }
}

@mixin generalBtn {
  padding: 0.4rem 1rem;
  width: fit-content;
  border-radius: 5px;
  background-color: $primaryColor;
  font-family: secondaryFont;
  outline: none;
  border: none;
  color: white;
}

@mixin generalBtn_1 {
  border-radius: 5px;
  background-color: $primaryColor;
  font-family: secondaryFont;
  outline: none;
  border: none;
  color: white;
  padding: 1rem 2rem;
  width: 250px;
  transition: 200ms ease-in-out;

  &:hover {
    box-shadow: 0px 1px 6px 4px #c1bebe;
  }
}

@mixin disableBtn {
  background-color: $fifthColor;
  color: $fourthColor;
  border: 1px solid $fourthColor;
  cursor: not-allowed;
  box-shadow: none !important;
}

.validation {
  color: #f5015b;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-family: secondaryFont;
  visibility: hidden;
  // transition: 500ms ease-in-out;

  i {
    margin-right: 0.3rem;
  }

  p {
    margin: 0;
  }
}

@mixin hoverBtn {
  margin-right: 1rem;
  border: 2px solid $primaryColor;
  background-color: transparent;
  border-radius: 3px;
  font-size: 1rem;
  padding: 0.3rem 0.8rem;
  color: $primaryColor;
  transition: 200ms ease-in-out;
  font-family: primaryFont;

  &:hover {
    background-color: $primaryColor;
    color: white;
  }
}

@mixin normalBtn {
  width: 100%;
  background-color: transparent;
  border: 2px solid #37358d;
  color: #37358d;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  height: 35px;
}

@mixin notCopied {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
