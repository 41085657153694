.lessonView_wrapper {
  &-heading {
    display: flex;
    align-items: center;

    img {
      width: 35px;
    }

    h1 {
      font-size: 1.5rem;
      font-family: primaryFont;
      padding-left: 0.5rem;
    }
  }

  &-ref {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .details {
      flex-wrap: wrap;
      display: flex;

      .data {
        p {
          padding: 0.5rem 1rem;
          background-color: $fifthColor;
          border-radius: 5px;
          color: $primaryColor;
          font-family: primaryFont;
          margin-bottom: 0.5rem;
          margin-right: 0.5rem;
          display: flex;

          label {
            color: black;
            padding-right: 0.3rem;
          }

          span {
            display: flex;
            align-items: center;
            color: $green;

            i {
              color: white;
              padding: 0.3rem;
              font-size: 0.7rem;
              margin-left: 0.3rem;
            }

            .green {
              background-color: $green;
              border-radius: 50%;
            }

            .red {
              padding: 0;
              font-size: 1rem;
              color: red;
            }
          }

          .red {
            color: red;
          }
        }

        button {
          font-family: primaryFont;
          all: unset;
          margin-bottom: 0.5rem;
          margin-right: 0.5rem;
          cursor: pointer;
        }

        &:last-child {
          display: flex;
        }
      }
    }

    button {
      border-radius: 5px;
      border: 1px solid $primaryColor;
      color: $primaryColor;
      padding: 0.5rem 1rem;
      font-family: primaryFont;
      margin-bottom: 0.5rem;
      cursor: pointer;
    }
  }

  &-dates {
    display: flex;

    h6 {
      color: $primaryColor;
      font-family: primaryFont;
      font-weight: bold;

      &:last-child {
        margin-left: 1rem;
      }
    }
  }

  &-rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .heading {
      display: flex;
      align-items: center;

      h1 {
        color: $tertiaryColor;
        font-size: 1.2rem;
        padding: 0;
        margin: 0;
        transition: 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          color: $primaryColor;
        }
      }

      .stars {
        font-size: 1.2rem;
        color: #ffc200;

        i {
          padding: 0 0.2rem;
        }

        & > i:first-child {
          padding-left: 0.5rem;
        }

        & > i:last-child {
          color: $seventhColor;
        }
      }
    }

    button {
      border-radius: 5px;
      border: 1px solid $primaryColor;
      color: $primaryColor;
      padding: 0.5rem 1rem;
      font-family: primaryFont;
      margin-bottom: 0.5rem;
      cursor: pointer;
    }
  }

  &-duplicate {
    button {
      border-radius: 5px;
      border: 1px solid $primaryColor;
      color: $primaryColor;
      padding: 0.5rem 1rem;
      font-family: primaryFont;
      margin-bottom: 0.5rem;
      cursor: pointer;
    }
  }

  &-description {
    h1 {
      padding: 1rem 0;
    }

    p {
      background-color: $fifthColor;
      padding: 1rem;
      font-family: primaryFont;
      border-radius: 5px;
      margin: 0 0 1rem;
    }
  }

  &-content {
    h1 {
      padding: 1rem 0;
    }

    .widget {
      margin: 0 0 1rem;

      &_data {
        background-color: $fifthColor;
        border-radius: 5px;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          cursor: pointer;
          display: none;
          color: $primaryColor;
        }

        &[aria-expanded='true'] .fa-chevron-up {
          display: block;
        }

        &[aria-expanded='false'] .fa-chevron-down {
          display: block;
        }

        .title {
          display: flex;
          align-items: center;

          img {
            width: 50px;
          }

          p {
            text-transform: capitalize;
            font-family: tertiaryFont;
            color: $primaryColor;
            padding-left: 0.5rem;
          }
        }
      }

      &_details {
        position: relative;
        background-color: $fifthColor;
        padding: 1rem;
        margin: 0 0 1rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        top: -0.5rem;
      }
    }

    // p {
    //   background-color: $fifthColor;
    //   font-family: primaryFont;
    //   border-radius: 5px;
    //   margin: 0 0 1rem;
    // }
  }
}
