.forget {
  min-height: 100vh;

  &-left {
    background-color: #ededf3;

    .verticalSpace {
      margin: 4rem 0 5rem;
    }

    .title {
      color: #37358d;
      font-size: 2.2rem;
      margin: 0 0 2rem;
      font-family: tertiaryFont;
    }

    form {
      .inputField {
        label {
          font-family: secondaryFont;
          font-style: normal;
          font-weight: 500;
          font-size: 1.1rem;
          line-height: 2.2rem;
          color: #37358d;
        }

        label + div {
          display: flex;
          border: 2px solid #37358d;
          border-radius: 5px;
          justify-content: space-between;
          align-items: center;

          input {
            margin-right: 0.5rem;
            padding: 0;
            border: none;
            background-color: transparent;
            outline: none;
            width: 100%;
            padding: 0.5rem;
            font-size: 1rem;
            font-family: secondaryFont;
          }

          i {
            margin: 0 0.8rem 0 0.5rem;
            color: #37358d;
            font-size: 1.2rem;
          }
        }
      }

      .submitBtn {
        @include primaryBtn;
      }

      .info-message {
        margin: 0.5rem 0 0;
        color: #1bb0e6;
        font-size: 0.75rem;
        // font-weight: 600;
        font-family: secondaryFont;

        i {
          margin-right: 0.3rem;
        }
      }
    }
  }

  &-right {
    background-color: #37358d;
    min-height: 100vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 80%;
      height: 65vh;
      object-fit: contain;
      //   animation: MoveUpDown 3s linear infinite;
      position: absolute;
      //   left: 0;
      //   bottom: 0;
    }

    @keyframes MoveUpDown {
      0%,
      100% {
        bottom: 130px;
      }

      50% {
        bottom: 100px;
      }
    }

    @media only screen and (min-width: 1024px) {
      img {
        width: 60%;
      }
    }
  }
}
