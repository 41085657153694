.custom-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 8rem;
  right: 1rem;

  button {
    background: none;
    border: none;

    i {
      color: $primaryColor;
      font-size: 2.5rem;
      transition: 200ms ease-in-out;
    }

    .disable {
      color: $seventhColor;
    }
  }
}
