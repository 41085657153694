.viewWidgetVideo {
  label {
    font-size: 1rem;
    font-family: secondaryFont;
    color: $primaryColor;
    padding: 0 0 0.3rem;
  }

  input,
  select,
  textarea {
    border: 1px solid $primaryColor;
    padding: 1rem 0.5rem;
    border-radius: 5px;
    font-family: secondaryFont;
    background-color: $ninthColor;
    outline: none;
    width: 100%;
  }

  &_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-field {
      width: 49%;

      label {
        font-size: 1rem;
        font-family: secondaryFont;
        color: $primaryColor;
        padding: 0 0 0.3rem;
      }

      input,
      select,
      textarea {
        border: 1px solid $primaryColor;
        padding: 1rem 0.5rem;
        border-radius: 5px;
        font-family: secondaryFont;
        background-color: $ninthColor;
        outline: none;
        width: 100%;
      }
    }
  }
}
