.video_widget {
  .required_field {
    color: $tertiaryColor;
    font-size: 1.5rem;
  }

  label {
    font-size: 1rem;
    font-family: secondaryFont;
    color: $primaryColor;
    padding: 0 0 0.3rem;
  }

  input,
  select,
  textarea {
    border: 1px solid $primaryColor;
    padding: 1rem 0.5rem;
    border-radius: 5px;
    font-family: secondaryFont;
    background-color: $fifthColor;
    outline: none;
    width: 100%;
  }

  &_title,
  .description {
    .inputField {
      display: flex;
      flex-direction: column;

      label {
        font-size: 1rem;
        font-family: secondaryFont;
        color: $primaryColor;
        padding: 0 0 0.3rem;
      }

      input,
      select,
      textarea {
        border: 1px solid $primaryColor;
        padding: 1rem 0.5rem;
        border-radius: 5px;
        font-family: secondaryFont;
        background-color: $fifthColor;
        outline: none;
      }

      textarea {
        width: 100%;
      }

      select {
        color: $primaryColor;
      }
    }
  }

  .description {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 2rem;

    .inputField {
      flex-grow: 1;
      margin-right: 1rem;
      width: 48%;
    }
  }

  .upload_pdf {
    margin: 0;

    form {
      width: 100% !important;
      height: 7rem !important;
    }
  }
}
