.lesson_listing {
  & > .title {
    color: $tertiaryColor;
    font-size: 1rem;
    margin: 0 0 1.5rem;
    font-family: primaryFont;
  }

  .row {
    margin: 0;
    padding: 0;

    & > div {
      padding: 0;
    }

    .selection {
      display: flex;
      flex-direction: column;
      height: 85px;
      justify-content: space-between;

      @include inputFields;

      select {
        margin-right: 2rem;
      }
    }
  }

  &-title {
    font-size: 1.1rem;
    font-family: secondaryFont;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 1rem;
      color: $primaryColor;

      & + span {
        color: $tertiaryColor;
        font-weight: 600;
        padding-left: 0.5rem;
      }
    }

    .actions {
      display: flex;
      flex-wrap: wrap;

      .manageOrder {
        padding: 0.7rem 1rem;
        color: white;
        background-color: $primaryColor;
        font-family: primaryFont;
        border: none;
        border-radius: 5px;
        font-size: 0.8rem;
        margin-right: 1rem;
      }
    }
  }

  &-list {
    .table-responsive {
      padding-right: 0.5rem;

      .table {
        font-family: secondaryFont;
        font-size: 1rem;
        text-transform: capitalize;
        white-space: 'nowrap';
        background-color: $fifthColor;
        border-radius: 5px;

        .row-odd {
          background-color: #edf3f3;
        }

        thead > tr > th {
          white-space: 'nowrap' !important;
        }

        tbody > tr > td {
          color: $primaryColor;
          white-space: 'nowrap' !important;

          .action {
            //   @include secondaryBtn;
            font-size: 0.9rem;
            padding: 0.2rem 0.6rem;
            border: 2px solid $primaryColor;
            background-color: transparent;
            color: $primaryColor;
            border-radius: 5px;
            border: 1px solid red;
          }

          &:last-child {
            width: 100px;

            i {
              font-size: 1.1rem;
              transition: 200ms ease-in-out;

              &:hover {
                cursor: pointer;
                transform: scale(1.3);
              }
            }

            i:last-child {
              margin: 0 1rem;
            }
          }
        }
        .status {
          p {
            text-align: center;
            border-radius: 20px;
            font-size: 0.7rem;
            padding: 0.1rem 0;
            // font-weight: bold;
            width: 100px;
            color: white;
          }
          .red {
            border: 1px solid #eb5757;
            background-color: #eb5757;
          }
          .green {
            // color: green;
            border: 1px solid #92c04c;
            background-color: #92c04c;
            text-align: center;
          }
        }
      }
    }
  }

  &-info {
    display: flex;
    font-family: secondaryFont;

    & > div {
      border: none;
      padding: 0.5rem;
      border-radius: 5px;
      background-color: $fifthColor;

      & > :first-child {
        color: black;
      }

      & > :not(:first-child) {
        color: $primaryColor;
      }

      & + .status {
        margin-left: 1rem;
        display: flex;

        span + span {
          display: flex;
          align-items: center;
          margin-left: 0.5rem;

          i {
            color: white;
            border-radius: 50%;
            padding: 0.2rem;
            font-size: 0.8rem;
          }
        }

        span + .active {
          color: #219653;
          i {
            margin-left: 0.3rem;
            background-color: #219653;
          }
        }

        span + .inactive {
          color: $tertiaryColor;
          i {
            color: $tertiaryColor;
            font-size: 1rem;
          }
        }
      }
    }

    .btn-link {
      text-decoration: none;
      margin-left: 0.5rem;
      padding: 0.6rem 0.6rem;
      font-size: 0.8rem;
      color: white;
      background-color: $primaryColor;
      font-family: primaryFont;
      border: none;
      border-radius: 5px;
    }

    @media only screen and (max-width: 500px) {
      flex-direction: column;
      width: fit-content;

      & div + div {
        margin: 1rem 0;
        margin-left: 0 !important;
      }
    }
  }

  .view_all {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    h1 {
      &:last-child {
        color: $tertiaryColor;
        font-size: 1rem;
        transition: 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          color: $primaryColor;
        }
      }
    }
  }

  .add_new_chapter {
    background-color: $fifthColor;
    border-radius: 5px;
    padding: 1rem;
    // margin: 0 2rem;

    .title {
      font-size: 1rem;
      color: $primaryColor;
      font-family: tertiaryFont;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 700px) {
    &-title {
      flex-direction: column;

      .createLesson_btn {
        margin: 0;
        margin-top: 1rem;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }
}
