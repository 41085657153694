.error-page {
  text-align: center;

  &_header {
    // background-color: $primaryColor;
    background-color: #4376dd;
    // background-image: url("https://img.freepik.com/free-photo/abstract-grunge-decorative-relief-navy-blue-stucco-wall-texture-wide-angle-rough-colored-background_1258-28311.jpg?w=2000");
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    h1 {
      color: white;
      margin: 0 0 1rem;
    }
  }
}
