.multiple_choice {
  label {
    font-size: 1rem;
    font-family: secondaryFont;
    color: $primaryColor;
  }

  .inputField {
    display: flex;
    flex-direction: column;

    label {
      font-size: 1rem;
      font-family: secondaryFont;
      color: $primaryColor;
      padding: 0 0 0.3rem;
    }

    input,
    select,
    textarea {
      border: 1px solid $primaryColor;
      padding: 1rem 0.5rem;
      border-radius: 5px;
      font-family: secondaryFont;
      background-color: $fifthColor;
      outline: none;
    }

    select {
      color: $primaryColor;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .title_level {
    display: flex;

    & div:first-child {
      width: 70%;
      margin-right: 1rem;
    }

    .inputField + .inputField {
      width: 30%;

      & > :last-child {
        border-color: $seventhColor;
        color: $fourthColor;
        background-color: $sixthColor;
      }
    }
  }

  .total_passing_questions {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    .inputField {
      label {
        text-align: justify;
        // white-space: nowrap;
      }

      input {
        margin-right: 1rem !important;
        margin: 0 0 1rem;
      }

      .no_of_question_show {
        border: 1px solid $primaryColor;
        padding: 0.7rem 2rem;
        border-radius: 5px;
        font-family: secondaryFont;
        background-color: $fifthColor;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          color: $tertiaryColor;
          border-radius: 50%;
          border: 1px solid $tertiaryColor;
          padding: 0.5rem;
          cursor: pointer;
        }
      }
    }
  }

  .two_add_btn {
    button,
    .add_question {
      width: 100%;
      text-align: start;
      padding: 1rem;
      font-size: 1.1rem;
      background-color: $secondaryColor;
      border-radius: 5px;
      font-family: secondaryFont;
      color: $primaryColor;
      border: 1px solid $secondaryColor;
    }

    .add_question_block {
      position: relative;
      margin: 1rem 0 0;
      padding: 1rem;
      background-color: $secondaryColor;
      border-radius: 5px;

      .question-form {
        .actions {
          div {
            display: flex;
          }
        }
      }
    }
  }

  .table_container {
    .table-content {
      border: 1px solid rgb(173, 171, 171);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      table {
        thead {
          .header {
            tr {
              display: flex;
              justify-content: space-between;

              th {
                // text-align: center;
                flex-grow: 1;
                .table-column-title {
                  width: auto;
                  white-space: unset;
                }
              }
            }
          }
        }

        tbody {
          .rowBg {
            tr {
              display: flex;
              justify-content: space-between;
              td {
                text-align: justify;
                flex-grow: 1;
                flex-basis: 0;
                .filter {
                  width: unset;
                  white-space: unset;

                  input {
                    flex-grow: 1;
                  }
                }
                .table-row-data {
                  width: unset;
                  white-space: unset;
                  text-overflow: unset;
                  overflow: visible;
                }

                .questionImage {
                  p {
                    width: 80px;
                    text-align: center;
                    font-size: 1.5rem !important;
                  }

                  img {
                    width: 70px;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.modal_container {
  .modal_container-content {
    .table_container {
      thead {
        .header {
          tr {
            display: flex;
            justify-content: space-between;

            th {
              // text-align: center;
              // flex-grow: 1;
              // flex-basis: 0;
              .table-column-title {
                width: unset;
                white-space: unset;
              }

              &:not(:first-child) {
                flex-grow: 1;
              }

              &:nth-child(2) {
                .table-column-title {
                  width: 500px;
                }
              }
            }
          }
        }
      }

      tbody {
        .rowBg {
          tr {
            display: flex;
            justify-content: space-between;
            align-items: center;
            td {
              // flex-grow: 1;
              // flex-basis: 0;
              // min-width: 0;

              &:not(:first-child) {
                flex-grow: 1;
                flex-basis: 0;
              }

              .table-row-data {
                width: unset;
                white-space: unset;
                // text-overflow: unset;
                // overflow: visible;
              }

              .action_questions {
                display: flex;
                font-size: 1.2rem;
                // color: $primaryColor;
                width: 60px;
                justify-content: space-between;

                i {
                  cursor: pointer;
                }
              }

              .questionImage {
                p {
                  width: 80px;
                  text-align: center;
                  font-size: 1.5rem !important;
                }

                img {
                  width: 70px;
                  object-fit: cover;
                }
              }

              &:nth-child(2) {
                .table-row-data {
                  text-align: justify;
                  width: 500px;
                }
              }
            }

            &:first-child {
              td {
                &:nth-child(2) {
                  .filter {
                    width: 500px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .edit_question_modal {
    h1 {
      color: $tertiaryColor;
      font-size: 1rem;
    }

    .modal_actions {
      display: flex;
      justify-content: space-between;

      button:first-child {
        @include generalBtn_1;
        background-color: $fifthColor;
        color: $primaryColor;
        font-weight: 600;
        border: 1px solid $primaryColor;
        white-space: nowrap;
        width: fit-content;
        margin-right: 0.5rem;
      }

      button + button {
        flex-grow: 1;
        @include generalBtn;
      }
    }
  }

  .submitBtn {
    @include hoverBtn;
    width: 100%;
  }
}
