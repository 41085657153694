.code_wrapper {
  text-align: end;

  .download {
    visibility: hidden;
  }
  .downloadBtn {
    @include generalBtn;
    padding: 0.6rem 1.5rem;
    text-decoration: none;
    margin-right: 0.5rem;
  }

  button {
    @include generalBtn;
    padding: 0.6rem 1.5rem;
  }
}
