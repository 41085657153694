@font-face {
  font-family: primaryFont;
  src: url('./Poppins-Regular.ttf');
}

@font-face {
  font-family: secondaryFont;
  src: url('./Poppins-Medium.ttf');
}

@font-face {
  font-family: tertiaryFont;
  src: url('./Poppins-SemiBold.ttf');
}
