.cross-btn {
  &_container {
    display: inline-block;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 50%;
    padding: 0.3rem;
  }

  i {
    font-size: 1.5rem;
    cursor: pointer;
    border: 1px solid rgb(224, 222, 222);
    box-shadow: 2px 2px 5px 2px rgb(205, 204, 204);
    border-radius: 50%;
    padding: 0.5rem;
    width: 40px;
    height: 40px;
    margin-left: 0.5rem;
  }
}
