.question {
  display: flex;
  // overflow-x: scroll;

  &_filters {
    padding: 1rem 0.5rem;
    // border: 1px solid green;
    width: 300px;
    display: flex;
    flex-direction: column;

    .title {
      color: $tertiaryColor;
      font-size: 1.15rem;
      font-family: primaryFont;
      margin: 0 0 1rem;
    }

    .inputFields {
      width: 100%;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      font-family: secondaryFont;
      background-color: $fifthColor;
      outline: none;
      margin: 0 0 1rem;
      color: $primaryColor;
    }
  }

  &_lists {
    flex-grow: 1;
    padding: 1rem;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .search {
        border: 1px solid $seventhColor;
        padding: 0.8rem 0;
        border-radius: 5px;
        background-color: $secondaryColor;
        margin: 0 0 1rem;
        width: 300px;

        input {
          width: 250px;
          font-family: secondaryFont;
          background-color: $secondaryColor;
          outline: none;
          border: none;
        }

        i {
          color: $tertiaryColor;
          padding: 0 0.5rem;
        }
      }

      button {
        @include generalBtn;
        color: $fifthColor;
        padding: 0.8rem 0;
        margin: 0 0 1rem;
        width: 300px;
      }

      .filter {
        @include normalBtn;
        height: unset;
        width: 300px;
        padding: 0.5rem 0;
        background-color: $secondaryColor;
        color: $tertiaryColor;
        border-width: 1px;
        display: none;
        font-size: 1.2rem;
      }
    }

    &-details {
      background-color: $fifthColor;
      padding: 1rem;
      border-radius: 5px;
      margin: 0 0 1rem;

      .firstRow,
      .secondRow,
      .thirdRow {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: $primaryColor;
        font-family: primaryFont;
      }

      .firstRow {
        .left {
          font-size: 1.1rem;
        }
        .right {
          font-size: 0.9rem;
          font-weight: 400;
        }

        margin: 0 0 0.5rem;
      }

      .secondRow {
        .right {
          font-size: 1rem;
          font-weight: 600;
        }
        margin: 0 0 0.5rem;
      }

      .thirdRow {
        .left,
        .right {
          font-size: 1rem;
          font-weight: 600;
        }

        .right {
          color: $tertiaryColor;
        }
        margin: 0 0 0.5rem;
      }

      .fourthRow {
        border-radius: 5px;
        background-color: $secondaryColor;
        padding: 1rem;
        text-align: justify;
        font-family: secondaryFont;
      }

      .fifthRow {
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;

        h1 {
          background-color: $tertiaryColor;
          margin-right: 1rem;
          color: white;
          font-size: 1rem;
          font-family: primaryFont;
          font-weight: 500;
          padding: 0.5rem 1rem;
          border-radius: 5px;
          margin-bottom: 1rem;
        }
      }

      .sixthRow {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .viewBtn {
          @include generalBtn;
          color: $fifthColor;
          padding: 0.8rem 0;
          width: 250px;
          margin: 0 0 0.5rem;
        }

        .actions {
          width: 300px;
          display: flex;
          justify-content: space-between;
          margin: 0 0 0.5rem;

          button {
            white-space: nowrap;
            @include normalBtn;
            height: unset;
            padding: 0.5rem 1rem;
            margin-right: 1rem;
            font-family: primaryFont;
          }
        }
      }
    }
  }

  &_type {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    .block {
      margin: 1rem;
      background-color: $sixthColor;
      width: 250px;
      height: 180px;
      padding: 0.5rem;
      border-radius: 5px;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      transition: 150ms;
      flex-grow: 1;
      text-align: center;

      img {
        width: 100px;
        height: 100px;
        border: 1px solid rgb(211, 210, 210);
        border-radius: 5px;
        object-fit: none;
        filter: drop-shadow(5px 5px 2px rgb(208, 207, 207));
      }

      span {
        margin: 0.5rem 0 0;
        font-size: 0.85rem;
        color: $primaryColor;
        font-family: primaryFont;
      }

      &:hover {
        border: 1px solid $primaryColor;
        background-color: $fifthColor;

        span {
          font-weight: bold;
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    &_lists {
      &-details {
        .secondRow {
          .left {
            display: none;
          }
        }

        .sixthRow {
          .viewBtn {
            width: 100%;
          }

          .actions {
            width: 100%;

            button + button {
              margin: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    &_filters {
      width: 450px;
    }
  }

  @media only screen and (max-width: 1000px) {
    &_filters {
      display: none;
    }

    &_lists {
      padding: 0;

      .header {
        .search {
          width: 100%;
        }

        button {
          width: 100%;
        }

        .filter {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
