.textAndImages {
  padding: 0 0 0.5rem;

  label {
    font-size: 1rem;
    color: $primaryColor;
    font-family: secondaryFont;
    padding: 0 0 0.2rem;
  }

  .row {
    margin: 0;
    padding: 0;

    div {
      padding: 0;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      padding: 1rem;
      border-radius: 5px;
      background-color: $secondaryColor;
      width: 250px;

      input[type='radio'] {
        width: 25px;
        height: 25px;
        padding: 0 1rem;
      }

      label {
        margin: 0 0.5rem;
      }
    }

    .active {
      border: 1px solid $primaryColor;
    }
  }

  .content_title {
    margin: 0;
    padding: 1rem 0 0;
    display: flex;
    flex-direction: column;

    input,
    textarea {
      border: 1px solid $primaryColor;
      background-color: transparent;
      font-size: 1rem;
      font-family: secondaryFont;
      outline: none;
      border-radius: 5px;
      padding: 1rem;
    }
  }
}
