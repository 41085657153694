.viewWidgetTextAndImage {
  label {
    font-size: 1rem;
    font-family: secondaryFont;
    color: $primaryColor;
    padding: 0 0 0.3rem;
  }

  &_type {
    display: flex;
    justify-content: space-between;

    p {
      flex-grow: 1;
      border: 1px solid $primaryColor;
      background-color: $secondaryColor;
      font-size: 1rem;
      font-family: secondaryFont;
      color: $primaryColor;
      border-radius: 5px;
      text-align: center;
      text-transform: capitalize;
      padding: 0.5rem 0;

      &:first-child {
        margin-right: 1rem;
      }
    }
    .active {
      background-color: $primaryColor;
      color: $secondaryColor;
    }
  }

  input,
  select,
  textarea {
    border: 1px solid $primaryColor;
    padding: 1rem 0.5rem;
    border-radius: 5px;
    font-family: secondaryFont;
    background-color: $fifthColor;
    outline: none;
    width: 100%;
  }

  &_content {
    img {
      width: 100%;
      height: 150px;
      object-fit: contain;
    }

    p {
      font-size: 1rem;
      color: $primaryColor;
      width: fit-content;
      font-family: secondaryFont;
      cursor: pointer;
      transition: 200ms ease-in-out;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
