.user_management {
  .inputField {
    label {
      font-size: 1rem;
      font-family: secondaryFont;
      color: $primaryColor;
      margin-bottom: 0.5rem;
    }

    input,
    select {
      border: none;
      padding: 1rem 0.5rem;
      border-radius: 5px;
      font-family: secondaryFont;
      background-color: $fifthColor;
      outline: none;
      width: 100%;
    }
  }

  button {
    padding: 0.5rem 2rem;
    width: fit-content;
    border-radius: 5px;
    background-color: $primaryColor;
    font-family: secondaryFont;
    outline: none;
    border: none;
    color: white;
    margin: 1rem 0 0 0;
  }

  & > .purchase {
    .purchase_user_info {
      .name {
        color: $primaryColor;
        font-family: secondaryFont;
        background-color: rgb(222, 222, 222);
        padding: 0.5rem;
      }
    }

    .purchase_details {
      .total > .title > h1 {
        font-size: 1rem;
        padding-bottom: 0.5rem;
      }

      .delete_action {
        i {
          color: rgb(240, 42, 42);
        }
      }

      .disable_action {
        i {
          color: rgb(53, 38, 9);
        }
      }
    }

    .cancel-button {
      background-color: rgb(255, 55, 55);
    }
  }
}

.user_details {
  .box {
    border-radius: 10px;
    padding-top: 10px;
    margin: 15px 2px ;
    padding-bottom: 10px;
    background-color: $secondaryColor;
  }

  div {
    font-size: 1rem;
    color: $primaryColor;
    font-family: primaryFont;
    font-weight: 600;
    line-height: 3vh;
  }
}
