.notification_form {
  .label {
    padding: 0;
    font-size: 1rem;
    font-family: secondaryFont;
    color: $primaryColor;
  }

  &-selections {
    .selection {
      display: flex;
      flex-wrap: wrap;

      & > div {
        display: flex;
        align-items: center;
        border: none;
        padding: 1rem;
        border-radius: 10px;
        font-family: secondaryFont;
        background-color: $fifthColor;
        outline: none;
        width: 200px;
        margin-right: 1rem;

        input {
          width: 25px;
          height: 25px;
          accent-color: $primaryColor;
        }

        label {
          margin-left: 0.5rem;
          text-transform: capitalize;
          width: 100%;
        }
      }

      .active {
        border: 2px solid $primaryColor;
      }
    }
  }

  &-segment {
    .category {
      //   border: 2px solid $primaryColor;
      //   border-radius: 10px;
      //   padding: 0.1rem;
      //   width: fit-content;

      select {
        // -webkit-appearance: none;
        // -moz-appearance: none;
        // border: none;
        // padding: 0.7rem;
        border: none;
        padding: 1rem 0.5rem;
        border-radius: 5px;
        font-family: secondaryFont;
        background-color: $fifthColor;
        outline: none;
        color: $primaryColor;
        width: 300px;
      }
    }
  }

  &-afterSegment {
    .inputField {
      display: flex;
      flex-direction: column;

      @include inputFields;
      width: 300px;
    }
  }

  &-body {
    .inputField {
      display: flex;
      flex-direction: column;

      @include inputFields;
    }
  }

  &-dateTime {
    .inputField {
      display: flex;
      flex-direction: column;

      @include inputFields;
      width: 300px;
    }
  }

  &-navigate {
    & > label {
      cursor: pointer;
    }

    & > label:hover {
      color: $tertiaryColor;
    }
  }

  &-actions {
    button {
      border: 2px solid $primaryColor;
      font-family: secondaryFont;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      background-color: transparent;
      margin-right: 1rem;
    }

    .cancelBtn {
      color: $primaryColor;
    }
    .sendBtn {
      color: white;
      background-color: $primaryColor;
    }
  }
}
