.modal_cross_icon {
  font-size: 1.5rem;
}

.customOverlay {
  background: rgba(0, 0, 0, 0.5) !important;
}

.customModal {
  background: white !important;
  max-width: 100% !important;
  border-radius: 5px;
  width: fit-content;
  padding: 0 !important;
}

.modal_container {
  display: flex;
  flex-direction: column;

  &-header {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0 4rem;
    }
  }

  hr {
    margin: 0;
  }

  &-content {
    padding: 2rem 1rem;
  }
}

.lesson_changeStatus {
  h1 {
    text-align: center;
  }

  &-actions {
    margin: 1rem 0 0;
    display: flex;
    justify-content: space-between;

    button {
      border: 2px solid $primaryColor;
      color: $primaryColor;
      width: 200px;
      padding: 0.5rem 0;
      font-family: secondaryFont;
      border-radius: 5px;
      transition: 200ms ease-in-out;

      &:hover {
        font-weight: bold;
        background-color: $primaryColor;
        color: $secondaryColor;
      }
    }
  }
}

.feedback_modal {
  width: 60vw;
  height: 60vh;
  overflow-y: auto;

  &-rating {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      color: $tertiaryColor;
      font-size: 1.2rem;
      padding: 0;
      margin: 0;
    }

    .stars {
      font-size: 1.2rem;
      color: #ffc200;

      i {
        padding: 0 0.2rem;
      }

      & > i:first-child {
        padding-left: 0.5rem;
      }

      & > i:last-child {
        color: $seventhColor;
      }
    }
  }

  &-details {
    margin: 1rem;
    border: 1px solid black;
    border-radius: 5px;
    padding: 1rem;
    background-color: $secondaryColor;

    .userInfo {
      display: flex;
      align-items: center;

      .image {
        max-width: 70px;

        img {
          border-radius: 50%;
          width: 100%;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
        }
      }

      .data {
        padding-left: 1rem;
        font-size: 1rem;
        .name {
          color: $primaryColor;
          font-family: secondaryFont;
        }
        .date {
          color: rgb(147, 141, 141);
        }
      }
    }

    .stars {
      font-size: 1.2rem;
      margin: 0.5rem 0;

      i {
        color: $seventhColor;
        padding: 0 0.2rem;
      }

      .yellow {
        color: #ffc200;
      }

      & > i:first-child {
        padding-left: 0.5rem;
      }
    }

    p {
      font-family: secondaryFont;
    }
  }
}

.dinarPackModal,
.codeModal,
.downloadCodesModal {
  form {
    div {
      display: flex;
      justify-content: center;
      .inputField {
        display: flex;
        flex-direction: column;
        margin: 0 1rem 1rem;
        width: 100%;

        @include inputFields;

        select {
          // width: 95%;
        }

        input[type='file'] {
          border: 2px dashed $tertiaryColor;
        }

        .validDate {
          color: $tertiaryColor;
          font-size: 0.8rem;
          text-transform: capitalize;
          font-family: secondaryFont;
        }
      }

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    .saveBtn {
      button {
        margin: 1rem 1rem 0;
        @include generalBtn;
        width: 100%;
      }

      @media only screen and (max-width: 600px) {
        button {
          width: 92%;
        }
      }
    }
  }
}

.navigate_where {
  .inputField {
    display: flex;
    flex-direction: column;

    @include inputFields;
  }

  form {
    & > .actions {
      display: flex;
      justify-content: space-between;

      button {
        border: 2px solid $primaryColor;
        font-family: secondaryFont;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        background-color: transparent;
        // margin-right: 1rem;
        width: 100%;
      }

      .cancelBtn {
        color: $primaryColor;
        margin-right: 1rem;
      }
      .sendBtn {
        color: white;
        background-color: $primaryColor;
      }
    }
  }
}

.advertisement-modal {
  & > {
    text-align: center;
  }

  img {
    width: 400px;
  }

  .inputField {
    display: flex;
    flex-direction: column;

    @include inputFields;
  }

  button {
    border: 2px solid $primaryColor;
    font-family: secondaryFont;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: transparent;
    margin-right: 1rem;
    color: white;
    background-color: $primaryColor;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .customModal {
    margin: 150px 0 0 !important;
    max-width: 100% !important;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .modal_container {
    h1 {
      font-size: 1rem;
      margin: 0 2rem;
    }
  }

  .feedback_modal {
    width: unset;
    height: unset;
    overflow: unset;

    &-details {
      margin: 1rem;
    }
  }

  .advertisement-modal {
    text-align: center;
    img {
      width: 100%;
    }
  }
}

.subscription {
  text-align: center;

  button {
    margin: 1rem 1rem 0;
    @include generalBtn;
    width: 150px;
  }

  button + button {
    background-color: red;
  }
}
