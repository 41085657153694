.lessonThirdForm {
  .note {
    color: $primaryColor;
    font-family: secondaryFont;
    margin: 0 0 1rem;

    i {
      font-size: 0.9rem;
      padding: 0.2rem;
      border: 1px solid $tertiaryColor;
      border-radius: 50%;
      color: white;
      background-color: $tertiaryColor;
      cursor: pointer;
    }
  }

  .title {
    font-size: 1.3rem;
  }

  .add_new_content {
    background-color: $fifthColor;
    padding: 1rem;
    border-radius: 5px;

    button {
      border: none;
      background-color: transparent;
      font-size: 1rem;
      color: $primaryColor;
      font-weight: 600;
      font-family: primaryFont;
      padding: 0;
    }
  }

  &_content {
    .title {
      font-size: 1rem;
      font-family: primaryFont;
    }

    .block {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;

      &_card {
        background-color: $sixthColor;
        width: 140px;
        height: 140px;
        padding: 0.5rem;
        margin: 0.5rem;
        border-radius: 5px;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        transition: 150ms;
        flex-grow: 1;
        text-align: center;

        img {
          width: 80px;
          height: 80px;
          border: 1px solid rgb(211, 210, 210);
          border-radius: 5px;
          object-fit: none;
          filter: drop-shadow(5px 5px 2px rgb(208, 207, 207));
        }

        span {
          margin: 0.5rem 0 0;
          font-size: 0.85rem;
          color: $primaryColor;
          font-family: primaryFont;
          font-weight: 500;
        }

        &:hover {
          border: 1px solid $primaryColor;
          background-color: $fifthColor;

          span {
            font-weight: bold;
          }
        }
      }
    }
  }

  .widgets {
    margin: 0 0 1rem;

    &_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $fifthColor;
      border-radius: 5px;
      padding: 1rem;
      font-size: 1 rem;
      font-family: primaryFont;
      color: $primaryColor;
      margin: 0 0 1rem;

      .dragPoint {
        cursor: pointer;
        i {
          padding: 0 0.1rem;
          color: $fourthColor;
        }
        padding-right: 1rem;

        &:hover {
          i {
            color: $tertiaryColor;
          }
        }
      }

      .toggle_div {
        flex-grow: 1;
        display: flex;
        align-items: center;

        a {
          all: unset;
          font-weight: bold;
          cursor: pointer;
          flex-grow: 1;

          span {
            color: $tertiaryColor;
          }
        }

        i {
          cursor: pointer;
          display: none;
          transition: opacity 1000ms ease-out;
        }

        &[aria-expanded='true'] .fa-chevron-up {
          display: block;
        }

        &[aria-expanded='false'] .fa-chevron-down {
          display: block;
        }
      }

      .move_to_lesson_select {
        padding: 4px;
      }

      .move_to_lesson_btn {
        border-radius: 5px;
        background-color: #37358d;
        outline: none;
        border: none;
        color: white;
        padding: 6px 12px;
        margin: 0 8px 0 4px;

        &:disabled {
          background-color: gray;
        }
      }

      .sort {
        display: flex;
        margin-left: 1rem;
        justify-content: space-between;
        position: relative;
        border-left: 2px solid $primaryColor;

        i {
          margin: 0 0.5rem;
          padding: 0.3rem;
          border: 1px solid $tertiaryColor;
          border-radius: 50%;
          color: white;
          background-color: $tertiaryColor;
          cursor: pointer;
        }
        // display: none;
      }
    }

    &_collapse {
      background-color: $fifthColor !important;
      position: relative;
      top: -1.2rem;
      border-radius: 0 0 5px 5px;

      & > div {
        padding: 1rem;
        font-size: 1rem;

        .actions {
          font-weight: 600;

          button {
            background-color: transparent;
            border: 1px solid $primaryColor;
            padding: 0.5rem 1rem;
            color: $primaryColor;
            margin-right: 1rem;
            border-radius: 5px;
            font-family: primaryFont;
            transition: 100ms ease-in-out;
          }

          button + button {
            color: white;
            background-color: $primaryColor;
          }

          button:hover {
            box-shadow: 1px 1px 6px 6px rgb(158, 157, 157);
          }
        }
      }
    }
  }

  &_form {
    .actions {
      display: flex;
      justify-content: space-between;

      .saveBtn {
        @include generalBtn_1;
        background-color: $fifthColor;
        color: $primaryColor;
        font-weight: 600;
        border: 1px solid $primaryColor;
      }

      .prevBtn {
        @include generalBtn_1;
        background-color: $fifthColor;
        color: $primaryColor;
        font-weight: 600;
        border: 1px solid $primaryColor;
      }

      & > div {
        button + button {
          margin-left: 1rem;
        }
      }

      .publishBtn {
        @include generalBtn_1;
      }

      .disable {
        @include disableBtn;
      }
    }
  }
}
