.lessonFirstForm {
  .row {
    margin: 0;
    padding: 0;

    .col-md-12,
    .col-md-4 {
      margin: 0;
      padding: 0;
    }
    margin: 0 0 2rem;

    .label_text {
      padding: 0;
      font-size: 1rem;
      font-family: secondaryFont;
      color: $primaryColor;
    }

    .inputField {
      display: flex;
      flex-direction: column;
      margin: 0 0 1rem;

      @include inputFields;

      select {
        width: 95%;
      }

      input[type='file'] {
        border: 2px dashed $tertiaryColor;
      }
    }

    .level {
      display: flex;
      align-items: center;
      border: none;
      padding: 1rem 0.5rem;
      border-radius: 5px;
      font-family: secondaryFont;
      background-color: $fifthColor;
      outline: none;
      width: 95%;
      margin: 0 0 2rem;
      // margin: 0 2rem 2rem 0;

      input {
        width: 25px;
        height: 25px;
        accent-color: $primaryColor;
      }

      img {
        margin: 0 0.5rem;
      }

      label {
        margin: 0;
        text-transform: capitalize;
        width: 100%;
      }
    }

    .upload {
      display: flex;
      flex-direction: column;
      width: 95%;
      margin: 0 0 2rem;

      label {
        font-size: 1rem;
        font-family: secondaryFont;
        color: $primaryColor;
      }

      &_icon {
        width: fit-content;
        padding: 1rem 0;
        border: 2px dashed $primaryColor;
        border-radius: 5px;
        background-color: $fifthColor;
        outline: none;
        display: flex;
        align-items: center;
        font-family: secondaryFont;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          label {
            color: $tertiaryColor;
            padding: 0 0 0.5rem;
          }

          input {
            color: $fourthColor;
          }
        }

        img {
          border: 1px solid $primaryColor;
          width: 70px;
          height: 70px;
          border-radius: 5px;
          padding: 0.05rem;
          margin: 0 1rem;
        }

        button {
          margin-right: 1rem;
          border: 2px solid $primaryColor;
          background-color: transparent;
          border-radius: 3px;
          font-size: 1rem;
          padding: 0.3rem 0.8rem;
          color: $primaryColor;
          transition: 200ms ease-in-out;

          &:hover {
            background-color: $primaryColor;
            color: white;
          }
        }
      }
    }

    .submitBtn {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;

      button {
        @include generalBtn_1;
      }

      button + button {
        background-color: $fifthColor;
        color: $primaryColor;
        font-weight: 600;
        border: 1px solid $seventhColor;
      }

      .disable {
        @include disableBtn;
      }
    }

    @media only screen and (max-width: 700px) {
      .upload {
        width: fit-content;
        margin: 0 0 1rem;

        &_icon {
          align-items: flex-start;
          flex-direction: column;
          padding-left: 1rem;

          & > div {
            margin: 1rem 0;
          }
        }
      }

      .submitBtn {
        flex-direction: column;

        button {
          margin: 0 0 1rem;
        }
      }
    }
  }
}

.lesson_confirm_modal_header {
  color: $tertiaryColor;
  font-size: 1.1rem;
  text-align: justify;
}

.lesson_confirm_modal_actions {
  margin: 1rem 0 0;
  display: flex;
  justify-content: space-between;

  button:first-child {
    @include generalBtn_1;
    background-color: $fifthColor;
    color: $primaryColor;
    font-weight: 600;
    border: 1px solid $primaryColor;
    white-space: nowrap;
    margin-right: 0.5rem;
  }

  button + button {
    // flex-grow: 1;
    @include generalBtn;
    // width: 150px;
  }

  button {
    width: 150px !important;
    padding: 0.5rem 0 !important;
  }
}
